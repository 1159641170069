<template>
  <div class="app-container unusual-container">
    <div class="main-body">
      <el-row class="call-title">
        <el-col :span="24">
          <i class="lc-tms-iconyunweiguanli icon-cls icon-cls-green" />
          <span class="title">异常事件表</span>
        </el-col>
      </el-row>
      <div ref="searchBar" class="form-cls">
        <el-form @heightChange="_getTableHeight()" size="small">
          <el-row>
            <el-col :span="6">
              <el-form-item label="运输公司:">
                <el-input
                  v-model.trim="queryParams.fleetName"
                  clearable
                  type="text"
                  class="input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="车牌号码:">
                <el-input
                  v-model.trim="queryParams.carPlate"
                  clearable
                  type="text"
                  class="input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                />
              </el-form-item>
            </el-col>
            <!-- <el-col :span="6">
              <el-form-item label="项目名称:">
                <el-input
                  v-model.trim="queryParams.projectName"
                  clearable
                  type="text"
                  class="input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                />
              </el-form-item>
            </el-col> -->
            <el-col :span="6">
              <el-form-item label="施工单位:">
                <el-input
                  v-model.trim="queryParams.transportName"
                  clearable
                  type="text"
                  class="input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="事件类型:">
                <el-select class="input-width-6" v-model="queryParams.abnormalType" multiple clearable>
                  <el-option :value="1" label="离线"></el-option>
                  <el-option :value="2" label="超时"></el-option>
                  <el-option :value="3" label="非审批"></el-option>
                  <!-- <el-option :value="4" label="超速"></el-option> -->
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item  label="核查状态:">
                <el-select class="input-width-6" v-model="queryParams.status" multiple clearable>
                  <el-option :value="0" label="未处理"></el-option>
                  <el-option :value="1" label="已处理"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="是否异常:">
                <el-select class="input-width-6" v-model="queryParams.isException" multiple clearable>
                  <el-option :value="0" label="是"></el-option>
                  <el-option :value="1" label="否"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="产生日期:">
                <el-date-picker
                  v-model="queryParams.createTime"
                  type="daterange"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item>
                <el-button plain size="small" type="primary" icon="el-icon-search" @click="queryList"> 查询 </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- </search-bar> -->
      </div>
      <div class="main-body">
        <vxe-toolbar ref="xToolbar" custom style="margin-left: 10px; padding-right: 10px">
          <template #buttons>
            <el-button plain key="addFile" size="small" @click="exportFile" type="primary"> 导出 </el-button>
          </template>
          <template #tools>
            <vxe-pager
              align="left"
              placement="top"
              :total="total"
              :current-page.sync="queryParams.currentPage"
              border
              :page-sizes="[100, 120, 150]"
              size="small"
              :page-size.sync="queryParams.pageSize"
              :layouts="['PrevJump', 'PrevPage', 'Jump', 'NextPage', 'NextJump', 'Sizes', 'Total']"
              @page-change="queryList('0')"
            />
          </template>
        </vxe-toolbar>
      </div>
      <el-row class="form-cls button-cls">
        <el-col :span="24">
          <vxe-table
            id="dataListTable"
            ref="table"
            v-loading="listLoading"
            class="sortable-column-demo"
            :export-config="{}"
            :data="dataList"
            fit
            :height="_tableHeight"
            element-loading-text="加载中"
            border
            stripe
            column-key
            resizable
            highlight-current-row
            :resizable-config="{ minWidth: 50 }"
            :custom-config="{ storage: true }"
            :scroll-y="{ gt: -1 }"
            show-overflow="tooltip"
            tooltip-effect="dark"
            size="small"
            style="width: 100%"
          >
            <vxe-table-column type="seq" title="序号" width="60" align="center" />
            <vxe-table-column field="carPlate" title="车牌号码" align="center" width="120" />
            <vxe-table-column field="transportName" title="运输公司" align="center" width="300" />
            <vxe-table-column field="projectName" title="项目名称" align="center" width="100"/>
            <vxe-table-column field="constructProjedt" title="施工单位" align="center" width="260" />
            <vxe-table-column field="abnormalTypeText" title="事件类型" align="center" width="100" />
            <vxe-table-column field="created" title="产生时间" align="center" width="180" />
            <vxe-table-column field="_statusText" title="核查状态" align="center" width="100"/>
            <vxe-table-column field="comments" title="处理意见" align="center" width="200" />
            <vxe-table-column align="center" fixed="right" title="操作" width="120">
              <template v-slot="{ row }">
                <span style="color: deepskyblue; cursor: pointer" @click="viewEvent(row)">查看</span>
                <span style="color: deepskyblue; cursor: pointer; margin-left: 10px;" @click="checkEvent(row)">核查</span>
              </template>
            </vxe-table-column>

          </vxe-table>
        </el-col>
      </el-row>
    </div>


      <el-drawer
        title="异常核查"
        :visible.sync="unusualVisible"
        :before-close="handleClose"
        size="1100px">
        <div class="unusual-content">
          <el-row class="unusual-row">
            <el-col :span="8">车牌号码：{{ checkDetail.carPlate }}</el-col>
            <el-col :span="8">运输公司：{{ checkDetail.fleetName }}</el-col>
            <el-col :span="8">项目名称：{{ checkDetail.projectName }}</el-col>
          </el-row>
          <el-row class="unusual-row">
            <el-col :span="8">施工单位：{{ checkDetail.constructProjedt }}</el-col>
            <el-col :span="8">事件类型：{{ checkDetail.abnormalTypeText }}</el-col>
            <el-col :span="8">产生时间：{{ checkDetail.created }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="8" v-if="checkDetail.carHeaderImg"><el-image style="width:300px" :src="checkDetail.carHeaderImg"></el-image></el-col>
            <el-col :span="8" v-if="checkDetail.carBodyImg"><el-image style="width:300px" :src="checkDetail.carBodyImg"></el-image></el-col>
          </el-row>
          <h4>轨迹信息：</h4>
          <el-row>
            <el-col :span="24">
              <div class="amap-section">
                <el-amap
                  :plugin="plugin"
                  :center="center"
                  :amap-manager="amapManager"
                  :zoom="zoom"
                  :events="events"
                  :resize-enable="true"
                  class="map"
                ></el-amap>
              </div>
            </el-col>
          </el-row>
          <el-form :model="checkForm" :inline="true" style="margin-top: 20px;">
            <template v-if="checkDetail._check">
              <el-form-item label="是否异常：">
                <el-select v-model="checkForm.status" style="width: 80px;">
                  <el-option :value="0" label="是"></el-option>
                  <el-option :value="1" label="否"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="处理意见：">
                <el-input v-model="checkForm.comments" style="width: 320px;"></el-input>
              </el-form-item>
            </template>
            <el-row class="unusual-row" v-else>
              <el-col :span="4">核查人：{{ checkDetail.checker }}</el-col>
              <el-col :span="4">是否异常：{{ checkDetail._exceptionText }}</el-col>
              <el-col :span="14">处理意见：{{ checkDetail.comments }}</el-col>
             
            </el-row>
            <el-form-item label="">
              <el-button type="primary" v-if="checkDetail._check" @click="checkFormSubmit">保存</el-button>
              <el-button @click="unusualVisible = false">关闭</el-button>
              <el-button @click="handlePre">上一单</el-button>
              <el-button @click="handleNext">下一单</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-drawer>
  </div>
</template>

<script>
import { AMapManager } from 'vue-amap'
import CalTableHeight from '@/mixins/calTableHeight'
import { centerUnusualList as queryList, centerUnusualExport , centerUnusualCheck, centerUnusualMap } from '@/api/base'
import downloadFile  from '@/utils/downloadFile'
const amapManager = new AMapManager()
let mapStartMarker; 
let mapEndMarker;
let mapPolyline
const abnormalTypeMap = {
      1: `离线`,
      2: `超时`, 
      3: `非审批`,
      4: `超速`
    }
export default {
  data() {
    return {
      total: 0,
      uploadUrl: '/admin-gov/project/upload', // admin-gov
      queryParams: {
        createTime: [],
        carPlate: '',
        transportName: '',
        abnormalType: [],
        status: [],
        isException: [],
        projectName: '',
        currentPage: 1,
        pageSize: 100
      },
      dataList: [],
      listRow: {},
      checkDetail: {
        _check: false
      },
      listLoading: false,
      unusualVisible: false,
      checkForm:{
        status: 1,
        comments: '',
      },
      // 地图
      center: [120.236713, 30.206855],
      map: null,
      plugin: [
        // 'AMap.OverView', // 地图鹰眼插件
        // 'AMap.ToolBar', //  地图工具条
        // 'AMap.MapType', //  类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制,
        'AMap.Autocomplete',
        'AMap.PlaceSearch',
      ],
      resizeEnable: false, //是否监控地图容器尺寸变化
      zoom: 16, // 设置初始化级别
      mapStyle: 'amap://styles/blue', // 使用的自
      amapManager,
      events: {
        init: map => {
          this.map = map
          console.log('map init ok', map)
        }
      }
    }
  },
  mixins: [CalTableHeight],
  computed: {},
  async mounted() {
    this.$nextTick(() => {
      // 手动将表格和工具栏进行关联,否则列配置将失效
      this.$refs.table.connect(this.$refs.xToolbar)
    })
    this.$nextTick(() => {
      this._getTableHeight()
    })
    this.queryList()
  },
  methods: {
    updateRow(row = {}){
      this.listRow = row
      this.checkDetail = {...this.checkDetail, ...row}
      return centerUnusualMap({
        id: row.id
      }).then(({data})=>{
        this.checkDetail = {...this.checkDetail, ...data}
        this.checkForm.comments = data.comments
        this.checkForm.status = data.isException
        this.checkDetail._exceptionText = ['异常', '非异常'][data.isException] || '异常'
        this.checkDetail.abnormalTypeText = abnormalTypeMap[data.abnormalType] || data.abnormalType,
        console.log('centerUnusualMap', data)
        this.startTrack(data)
        return data
      })
    },
    viewEvent(row){
      this.unusualVisible = true
      this.checkDetail._check = false
      this.updateRow(row)
    },
    checkEvent(row){
      this.unusualVisible = true
      this.checkDetail._check = true
      this.updateRow(row)
    },
    handleClose(done){
      if(mapStartMarker){
        this.map.remove(mapStartMarker)
      }
      if(mapEndMarker){
        this.map.remove(mapEndMarker)
      }
      if(mapPolyline){
        this.map.remove(mapPolyline)
      }
      done && done()
    },
    handlePre(){
      const { preId, _check } = this.checkDetail
      if(!preId){
        this.$message.error('没有上一个了')
        return
      }
      this.handleClose()
      if(_check){
        this.checkEvent({id: preId})
      }else {
        this.viewEvent({id: preId})
      }
    },
    handleNext(){
      const { nextId, _check } = this.checkDetail
      if(!nextId){
        this.$message.error('没有下一个了')
        return
      }
      this.handleClose()
      if(_check){
        this.checkEvent({id: nextId})
      }else {
        this.viewEvent({id: nextId})
      }
    },
    checkFormSubmit(){
      const row = this.listRow
      const { comments , status} = this.checkForm
      centerUnusualCheck({
        isException: status,
        id: row.id,
        // status,
        comments
      }).then(()=>{
        this.$message.success('核查成功')
        this.queryList()
      })
    },
    exportFile(){
      const { createTime = [], abnormalType, status,isException } = this.queryParams
      if(!createTime){
        createTime = []
      }
      centerUnusualExport({
        ...this.queryParams,
        startDate: createTime[0] || '',
        endDate: createTime[1] || '',
        abnormalType: abnormalType.join(','),
        status: status.join(','),
        isException: isException.join(','),
        createTime: undefined
      }).then((res)=>{
        downloadFile(res)
      })
    },
    queryList() {
      this.listLoading = true
      const { createTime = [], abnormalType, status,isException } = this.queryParams
      if(!createTime){
        createTime = []
      }
      return queryList({
        ...this.queryParams,
        startDate: createTime[0] || '',
        endDate: createTime[1] || '',
        abnormalType: abnormalType.join(','),
        status: status.join(','),
        isException: isException.join(','),
        createTime: undefined
      }).then(({ data }) => {
          this.dataList = (data.records || []).map((ele)=>{
            return {
              ...ele,
              abnormalTypeText: abnormalTypeMap[ele.abnormalType] || ele.abnormalType,
              _statusText: ['未处理', '已处理'][ele.status] || ele.status,
              xxx: '--'
            }
          })
          this.total = data.total
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    getMapMarker(lineArr) {
      return {
        startMarker: new AMap.Marker({
          position: new AMap.LngLat(lineArr[0][0], lineArr[0][1]),
          offset: new AMap.Pixel(-13, -30),
          icon: new AMap.Icon({
            size: new AMap.Size(25, 34), // 图标尺寸
            image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png', // 图标的取图地址
            imageSize: new AMap.Size(135, 40), // 图标所用图片大小
            imageOffset: new AMap.Pixel(-9, -3) // 图标取图偏移量
          }),
        }),
        endMarker: new AMap.Marker({
          position: new AMap.LngLat(lineArr[lineArr.length - 1][0], lineArr[lineArr.length - 1][1]),
          offset: new AMap.Pixel(-13, -30),
          icon: new AMap.Icon({
            size: new AMap.Size(25, 34),
            image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
            imageSize: new AMap.Size(135, 40),
            imageOffset: new AMap.Pixel(-95, -3)
          })
        })
      }
    },
    startTrack(data) {
      const _this = this
      const map = amapManager.getMap()
      if(!_this.map) {
        _this.map = map 
      }
      // 轨迹点
      let lineArr = ( data.abnormalGpsDataList ||  []).map((ele)=>{
        return [ele.longitude, ele.latitude ]
      })
      // let position

      console.log(lineArr)

      // if (lineArr == null || (Array.isArray(lineArr) && lineArr.length === 0)) {
      //   position = [116.478935, 39.997761]
      //   lineArr = _this.getLineArr()
      // } else {
      //   position = lineArr[0]
      // }

      const { startMarker, endMarker } = this.getMapMarker(lineArr)

      // startMarker.setLabel({
      //   offset: new AMap.Pixel(0, 80),  //设置文本标注偏移量
      //   direction: 'center',
      //   content: ` <div class="map-card">
      //         <div class="map-card-title">${data.beginCarAddress || '标题'}</div>
      //         <div class="map-card-image map-card-container">
      //           <img class="map-card-image" data-type="begin" src="${data.beginCarImg1}" alt="" />
      //           <img class="map-card-image" data-type="begin" src="${data.beginCarImg2}" alt="" />
      //         </div>
      //         <div class="map-card-footer">到达时间：${data.beginCarTime || ''}</div>
      //       </div>`,
      // });

      // endMarker.setLabel({
      //   offset: new AMap.Pixel(0, -80),  //设置文本标注偏移量
      //   direction: 'center',
      //   content: `
      //     <div class="map-card">
      //       <div class="map-card-title">${data.endCarAddress || '标题'}</div>
      //       <div class="map-card-image  map-card-image-container">
      //         <img class="map-card-image" data-type="enter" src="${data.endCarImg1}" alt="" />
      //         <img class="map-card-image" data-type="enter" src="${data.endCarImg2}" alt="" />
      //       </div>
      //       <div class="map-card-footer">到达时间：${data.endCarTime || ''}</div>
      //     </div>`,
      // });

      // 绘制轨迹
      let polyline = new AMap.Polyline({
        map: map,
        path: lineArr,
        showDir: true,
        strokeColor: '#28F', //线颜色
        strokeWeight: 6, //线宽
        strokeOpacity: 1, //线透明度
        strokeStyle: 'solid' //线样式
      })
      mapStartMarker = startMarker
      mapEndMarker = endMarker
      mapPolyline = polyline
      map.add([startMarker, endMarker])
      // 自动适配视图
      map.setFitView()
    },
    getLineArr() {
      return [
        [116.478935, 39.997761],
        [116.478939, 39.997825],
        [116.478912, 39.998549],
        [116.478912, 39.998549],
        [116.478998, 39.998555],
        [116.478998, 39.998555],
        [116.479282, 39.99856],
        [116.479658, 39.998528],
        [116.480151, 39.998453],
        [116.480784, 39.998302],
        [116.480784, 39.998302],
        [116.481149, 39.998184],
        [116.481573, 39.997997],
        [116.481863, 39.997846],
        [116.482072, 39.997718],
        [116.482362, 39.997718],
        [116.483633, 39.998935],
        [116.48367, 39.998968],
        [116.484648, 39.999861]
      ]
    },
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.input-cls {
  width: 260px;
  font-size: 14px;
  // feat: 搜索栏过多收起
  margin-right: 10px;
}

.icon-cls-green {
  color: #67c23a;
}

.dialog-row {
  margin-top: 10px;
}

.unusual-content{
  padding: 0 20px;
}
.unusual-row{
  padding: 6px 0;
}
</style>

<style rel="stylesheet/scss" lang="scss">
.amap-section {
  height: 400px;
}

.unusual-container{
  .el-drawer__header {
    margin-bottom: 16px;
  }
}

.dialog-footer{
  margin-top:20px;
}
.validFieldClass .el-input__inner {
  border-color: #f56c6c;
}

.el-pagination {
  float: right;
}

.sortable-column-demo .vxe-header--row .vxe-header--column.sortable-ghost,
.sortable-column-demo .vxe-header--row .vxe-header--column.sortable-chosen {
  background-color: #dfecfb;
}

.vxe-table--footer .col-blue {
  background: #f2f5f0;
}

/*单号复制样式 开始*/
.copy-content {
  right: 0.1em;
  top: 0.1em;
  float: right !important;
  position: absolute !important;
}

.copy-content:hover {
  background-color: #1482f0;
  float: right !important;
  height: 1.8em;
  width: 1.8em;
}

.write-color {
  border-color: #f0f0f0;
}

/*单号复制样式 结束*/
.input-width-6 {
  width: 60% !important;
}

.input-width-7 {
  width: 70% !important;
}

.input-width-8 {
  width: 80% !important;
}

.input-width-16 {
  width: 300px;
}
</style>
